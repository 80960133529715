import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Space, Input } from "antd";
import ButtonField from "../../../../../common/htmlComponents/ButtonField";
import NumberField from "../../../../../common/htmlComponents/NumberField";
import {
  addClassificationAPI,
  addClassificationFormulation,
  resetAfterUpdate,
  updateClassificationAPI,
  updateClassificationFormulation,
} from "./Plant&LabSlice";
import ClassificationList from "./ClassificationList";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../../../../utils/Notifications";
import PlantFileUpload from "./PlantFileUpload";
import LabFileUpload from "./LabFileUpload";
import RowMaterialFileUpload from "./RowMaterialFileUpload";
import FinishedGoodFileUpload from "./FinishedGoodFileUpload";
import PackagingMaterialFileUpload from "./PackagingMaterialFileUpload";
import { getRequest, patchRequest } from "../../../../../axios";
import TextAreaField from "../../../../../common/htmlComponents/TextAreaField";
import { useUnsavedChanges } from "../../../../UnsavedChangesProvider";

const PlantAndLab = (props) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const formRef1 = useRef();
  const dispatch = useDispatch();
  const { setCurrentTab } = props;
  const { setUnsavedChanges } = useUnsavedChanges();
  const classificationById = useSelector(
    (state) => state.plantLab.classification
  );
  const classification = classificationById && classificationById.results;
  const classificationAPI_id = useSelector(
    (state) => state.plantLab.classificationAPI_id
  );

  const classificationFormulationById = useSelector(
    (state) => state.plantLab.classificationFormulation
  );
  const classificationFormulation =
    classificationFormulationById && classificationFormulationById.results;
  const classificationFormulation_id = useSelector(
    (state) => state.plantLab.classificationFormulation_id
  );

  const initialState = {
    plant_machine_detail: "",
    lab_instrument_qc_detail: "",
    plant_machine_description: "",
    lab_and_qc_description: "",
    rm_detail: "",
    fg_detail: "",
    pm_detail: "",
    rm_description: "",
    fg_description: "",
    pm_description: "",
    no_of_classified_area: null,
    classification: null,
    classification_API_id: classificationAPI_id ? classificationAPI_id : null,
    classification_formulation_id: classificationFormulation_id
      ? classificationFormulation_id
      : null,
  };
  const [btnnLoading, setBtnnLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const [newClassificationAdded, setNewClassificationAdded] = useState(false);
  const [rmfileLink, setRMFileLink] = useState(null);
  const [fgfileLink, setFGFileLink] = useState(null);
  const [pmfileLink, setPMFileLink] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPlantLabUpload = async () => {
      try {
        let apiResponse;
        if (props.facility_type_id) {
          apiResponse = await getRequest(
            "facility/list-create-api-plant-lab/" + props.facility_type_id
          );
        }
        if (props.formulationMaster) {
          apiResponse = await getRequest(
            "facility/list-create-formulations-plant-lab/" +
              props.formulationMaster
          );
        }
        if (apiResponse.data.results[0]) {
          setRMFileLink(apiResponse.data.results[0].rm_upload);
          setFGFileLink(apiResponse.data.results[0].fg_upload);
          setPMFileLink(apiResponse.data.results[0].pm_upload);

          setState({ ...apiResponse.data.results[0] });
          formRef1.current.setFieldsValue({
            rm_description: apiResponse.data.results[0].rm_description,
            fg_description: apiResponse.data.results[0].fg_description,
            pm_description: apiResponse.data.results[0].pm_description,
            plant_machine_description:
              apiResponse.data.results[0].plant_machine_description,
            lab_and_qc_description:
              apiResponse.data.results[0].lab_and_qc_description,
          });
        } else {
          ErrorNotificationMsg("error in get description");
        }
      } catch (error) {
        ErrorNotificationMsg(error);
      }
    };
    getPlantLabUpload();
  }, [props.facility_type_id, props.formulationMaster, t, formRef1]);
  const handleClassificationChange = (changedValues, allValues) => {
    // Check if the changed values include the fields you want to track
    if (
      "classification" in changedValues ||
      "no_of_classified_area" in changedValues
    ) {
      // setChild1(true);
      // Assuming currentTab state has child1 and child2 properties
      setCurrentTab((prevTab) => ({
        ...prevTab,
        // child1: false,
        child2: true, // Set child2 to false or adjust based on your requirement
      }));
      // setUnsavedChanges(true);
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        // outerClickChild1: false,
        outerClickChild2: true,
      }));
    }
  };
  const handleFormChange = (changedValues, allValues) => {
    // Check if the changed values include the fields you want to track
    if (
      "plant_machine_description" in changedValues ||
      "lab_and_qc_description" in changedValues ||
      "rm_description" in changedValues ||
      "fg_description" in changedValues ||
      "pm_description" in changedValues
    ) {
      // setChild1(true);
      // Assuming currentTab state has child1 and child2 properties
      setCurrentTab((prevTab) => ({
        ...prevTab,
        child1: true,
        // child2: false, // Set child2 to false or adjust based on your requirement
      }));
      // setUnsavedChanges(true);
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        outerClickChild1: true,
        // outerClickChild2: false,
      }));
    }
  };
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value ? value : null });
  };

  // const resetField = () => {
  //   dispatch(resetAfterUpdate(true));
  //   setState({
  //     ...state,
  //     no_of_classified_area: null,
  //     classification: null,
  //     classification_API_id: null,
  //     classification_formulation_id: null,
  //   });
  //   formRef.current.setFieldsValue(initialState);
  // };

  let classification_ID;
  if (props.plant_lab_api_id) {
    classification_ID = state.classification_API_id
      ? state.classification_API_id
      : null;
  }
  if (props.plantLab_formulation_id) {
    classification_ID = state.classification_formulation_id;
  }

  useEffect(() => {
    if (classification) {
      formRef.current.setFieldsValue({
        no_of_classified_area: classification[0].no_of_classified_area,
        classification: classification[0].classification,
      });
      setState({
        ...state,
        classification_API_id: classificationAPI_id,
        no_of_classified_area: classification[0].no_of_classified_area,
        classification: classification[0].classification,
      });
    }
    if (classificationFormulation) {
      formRef.current.setFieldsValue({
        no_of_classified_area:
          classificationFormulation[0].no_of_classified_area,
        classification: classificationFormulation[0].classification,
      });
      setState({
        ...state,
        classification_formulation_id: classificationFormulation_id,
        no_of_classified_area:
          classificationFormulation[0].no_of_classified_area,
        classification: classificationFormulation[0].classification,
      });
    }
  }, [
    classification,
    classificationFormulation,
    classificationAPI_id,
    classificationFormulation_id,

    formRef,
  ]);

  const handleSubmit = async () => {
    try {
      setBtnnLoading(true);
      if (classification_ID === null || classification_ID === undefined) {
        if (props.plant_lab_api_id) {
          await addClassificationAPI({
            id: props.plant_lab_api_id,
            no_of_classified_area: state.no_of_classified_area,
            classification: state.classification,
          });
          setState({
            ...state,
            classification_API_id: null,
            no_of_classified_area: null,
            classification: null,
          });
          setCurrentTab((prevTab) => ({
            ...prevTab,
            // child1: false,
            child2: false, // Set child2 to false or adjust based on your requirement
          }));
          setUnsavedChanges((prevTab) => ({
            ...prevTab,
            // outerClickChild1: false,
            outerClickChild2: false,
          }));
        }
        if (props.plantLab_formulation_id) {
          await addClassificationFormulation({
            id: props.plantLab_formulation_id,
            no_of_classified_area: state.no_of_classified_area,
            classification: state.classification,
          });
          setState({
            ...state,
            classification_formulation_id: null,
            no_of_classified_area: null,
            classification: null,
          });
        }
        formRef.current.setFieldsValue(initialState);
        SuccessNotificationMsg(t("msg_addClassificationSuccess"));
        setNewClassificationAdded(!newClassificationAdded);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          // child1: false,
          child2: false, // Set child2 to false or adjust based on your requirement
        }));
        // setUnsavedChanges(false);
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          // outerClickChild1:false,
          outerClickChild2: false,
        }));
      } else {
        if (props.plant_lab_api_id) {
          await updateClassificationAPI({
            id: classificationAPI_id,
            no_of_classified_area: state.no_of_classified_area,
            classification: state.classification,
          });
          setState({
            ...state,
            classification_API_id: null,
            no_of_classified_area: null,
            classification: null,
          });
          setCurrentTab((prevTab) => ({
            ...prevTab,
            // child1: false,
            child2: false, // Set child2 to false or adjust based on your requirement
          }));
          // setUnsavedChanges(false);
          setUnsavedChanges((prevTab) => ({
            ...prevTab,
            // outerClickChild1: false,
            outerClickChild2: false,
          }));
        }
        if (props.plantLab_formulation_id) {
          await updateClassificationFormulation({
            id: classificationFormulation_id,
            no_of_classified_area: state.no_of_classified_area,
            classification: state.classification,
          });
          setState({
            ...state,
            classification_formulation_id: null,
            no_of_classified_area: null,
            classification: null,
          });
        }
        dispatch(resetAfterUpdate(true));
        formRef.current.setFieldsValue(initialState);
        SuccessNotificationMsg(t("msg_updateClassificationSuccess"));
        setNewClassificationAdded(!newClassificationAdded);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          // child1: false,
          child2: false, // Set child2 to false or adjust based on your requirement
        }));
        // setUnsavedChanges(false);
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          // outerClickChild1: false,
          outerClickChild2: false,
        }));
      }
      setBtnnLoading(false);
    } catch (error) {
      setBtnnLoading(false);
      ErrorNotificationMsg(t("err_classificationSuccess"));
    }
  };

  const handleDescription = async () => {
    const payload = {
      rm_description: state.rm_description,
      fg_description: state.fg_description,
      pm_description: state.pm_description,
      plant_machine_description: state.plant_machine_description,
      lab_and_qc_description: state.lab_and_qc_description,
    };

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("plant_machine_detail", "");
      formData.append("lab_instrument_qc_detail", "");
      formData.append("rm_detail", "");
      formData.append("fg_detail", "");
      formData.append("pm_detail", "");

      formData.append("form_data", JSON.stringify(payload));

      let apiResponse;
      if (props.facility_type_id) {
        apiResponse = await patchRequest(
          "facility/list-create-api-plant-lab/" + props.facility_type_id,
          formData
        );
      }
      if (props.formulationMaster) {
        apiResponse = await patchRequest(
          "facility/list-create-formulations-plant-lab/" +
            props.formulationMaster,
          formData
        );
      }
      if (apiResponse.data.success) {
        setState({
          ...state,
          rm_description: apiResponse.data.data.rm_description,
          fg_description: apiResponse.data.data.fg_description,
          pm_description: apiResponse.data.data.pm_description,
          plant_machine_description:
            apiResponse.data.data.plant_machine_description,
          lab_and_qc_description: apiResponse.data.data.lab_and_qc_description,
        });
        SuccessNotificationMsg(t("msg_addWarehousingCapabilitiesSuccess"));
        setLoading(false);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          child1: false,
          // child2: false, // Set child2 to false or adjust based on your requirement
        }));
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          outerClickChild1: false,
          // outerClickChild2: false,
        }));
      } else {
        setLoading(false);
        ErrorNotificationMsg(t("err_uploadSuccess"));
      }
    } catch (error) {
      setLoading(false);
      ErrorNotificationMsg(t("err_uploadSuccess"));
    }
  };
  return (
    <>
      <div className="plant-lab">
        <Form
          onFinish={handleDescription}
          autoComplete="off"
          ref={formRef1}
          onValuesChange={handleFormChange}
        >
          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={12}>
              <h3>{t("plant_machinery_details")}</h3>
              <div className="graybox">
                <div>
                  <TextAreaField
                    name="plant_machine_description"
                    label={t("pl_description_1")}
                    handleInputChange={handleInputChange}
                    max={1000}
                    rows={4}
                  />
                </div>
                {/* <PlantFileUpload
                  facility_type_id={props.facility_type_id}
                  formulationMaster={props.formulationMaster}
                /> */}
              </div>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <h3>{t("lab_instrument_details")}</h3>
              <div className="graybox">
                <div>
                  <TextAreaField
                    name="lab_and_qc_description"
                    label={t("pl_description_1")}
                    handleInputChange={handleInputChange}
                    max={1000}
                    rows={4}
                  />
                </div>
                {/* <LabFileUpload
                  facility_type_id={props.facility_type_id}
                  formulationMaster={props.formulationMaster}
                /> */}
              </div>
            </Col>
          </Row>
          <h3> {t("Warehousing Capabilities")}</h3>
          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={8}>
              <h3 className="subttl2">Raw Material</h3>
              <div className="graybox">
                <div>
                  <TextAreaField
                    name="rm_description"
                    label={t("pl_description_segrigation_1")}
                    handleInputChange={handleInputChange}
                    max={1000}
                    rows={4}
                  />
                </div>
                {/* <RowMaterialFileUpload
                  facility_type_id={props.facility_type_id}
                  formulationMaster={props.formulationMaster}
                  fileLink={rmfileLink}
                  setFileLink={setRMFileLink}
                  rm_description={state.rm_description}
                  fg_description={state.fg_description}
                  pm_description={state.pm_description}
                  plant_machine_description={state.plant_machine_description}
                  lab_and_qc_description={state.lab_and_qc_description}
                /> */}
              </div>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <h3 className="subttl2">Finished Good</h3>
              <div className="graybox">
                <div>
                  <TextAreaField
                    name="fg_description"
                    label={t("pl_description_segrigation_1")}
                    handleInputChange={handleInputChange}
                    max={1000}
                    rows={4}
                  />
                </div>
                {/* <PlantFileUpload /> */}
                {/* <FinishedGoodFileUpload
                  facility_type_id={props.facility_type_id}
                  formulationMaster={props.formulationMaster}
                  fileLink={fgfileLink}
                  setFileLink={setFGFileLink}
                  rm_description={state.rm_description}
                  fg_description={state.fg_description}
                  pm_description={state.pm_description}
                  plant_machine_description={state.plant_machine_description}
                  lab_and_qc_description={state.lab_and_qc_description}
                /> */}
              </div>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <h3 className="subttl2">Packaging Material</h3>
              <div className="graybox">
                <div>
                  <TextAreaField
                    name="pm_description"
                    label={t("pl_description_segrigation_1")}
                    handleInputChange={handleInputChange}
                    max={1000}
                    rows={4}
                  />
                </div>
                {/* <PlantFileUpload /> */}
                {/* <PackagingMaterialFileUpload
                  facility_type_id={props.facility_type_id}
                  formulationMaster={props.formulationMaster}
                  fileLink={pmfileLink}
                  setFileLink={setPMFileLink}
                  rm_description={state.rm_description}
                  fg_description={state.fg_description}
                  pm_description={state.pm_description}
                  plant_machine_description={state.plant_machine_description}
                  lab_and_qc_description={state.lab_and_qc_description}
                /> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="savebtn">
              <ButtonField
                type="primary"
                onClick={handleDescription}
                loading={loading}
                label={t("save")}
              />
            </Col>
          </Row>
        </Form>
      </div>

      <div className="plant-lab">
        <div className="graybox">
          <Form
            onFinish={handleSubmit}
            autoComplete="off"
            ref={formRef}
            onValuesChange={handleClassificationChange}
          >
            <Row gutter={[15]}>
              <Col xs={24} sm={12} lg={16}>
                <Space size={[16, 16]}>
                  <Form.Item
                    name="no_of_classified_area"
                    label={t("pl_no_of_classified_area")}
                    rules={[
                      {
                        required: true,
                        message: (
                          <Trans>{t("val_no_of_classified_area")}</Trans>
                        ),
                      },
                      {
                        // pattern: /^[0-9]+$/,
                        // pattern: /^[a-zA-Z0-9]+$/,
                    pattern: /^[a-zA-Z0-9\s]+(\.[0-9]+)?$/,
                        message: (
                          <Trans>{t("val_no_of_classified_area")}</Trans>
                        ),
                      },
                    ]}
                  >
                    <Input
                      // type="number"
                      placeholder={t("pl_no_of_classified_area")}
                      onChange={(value) =>
                        setState({
                          ...state,
                          no_of_classified_area: value.target.value,
                          // no_of_classified_area: Number(value.target.value),
                        })
                      }
                    />
                  </Form.Item>
                  <NumberField
                    name="classification"
                    label={t("pl_classification")}
                    placeholder={t("pl_classification")}
                    rules={t("val_classification")}
                    pattern={"^[a-zA-Z0-9\\s]+(\\.[0-9]+)?$"}

                    handleInputChange={handleInputChange}
                    max={20}
                  />
                </Space>
              </Col>

              <Col xs={24} sm={12} lg={8}>
                <div className="savebtnclassification">
                  <Space size={[16, 16]}>
                    <ButtonField
                      type="primary"
                      htmlType="submit"
                      loading={btnnLoading}
                      label={t("add")}
                    />
                    {/* {classification_ID !== null && (
                      <ButtonField
                        type="primary"
                        loading={btnnLoading}
                        onClick={resetField}
                        label={t("cancel")}
                      />
                    )} */}
                  </Space>
                </div>
              </Col>
            </Row>
          </Form>
          <ClassificationList
            plant_lab_api_id={props.plant_lab_api_id}
            plantLab_formulation_id={props.plantLab_formulation_id}
            newClassificationAdded={newClassificationAdded}
            setCurrentTab={setCurrentTab}
          />
        </div>
      </div>
    </>
  );
};

PlantAndLab.propTypes = {
  facility_type_id: PropTypes.number,
  formulationMaster: PropTypes.number,
  plant_lab_api_id: PropTypes.number,
  plantLab_formulation_id: PropTypes.number,

  setCurrentTab: PropTypes.func,
};

export default PlantAndLab;
